import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

import SVGjakobar from 'assets/svg/jakobar-logo.svg';

export default () => (
  <>
    <Helmet title="Home" />

    <Intro>
      <SVGjakobar />
    </Intro>

    <BlockText
      heading="jakobar.is"
      description="Web developer and more..."
    />
  </>
);
